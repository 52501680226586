import './index.css';
import CallToAction from '../../components/cta';
import PageHeader from '../../components/header';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const VisionAndMission = () => {
    return(
        <div className='vis-mis-bg'>
            <div className='container'>
                <div className='max-width'>
                <div className='two-col-container'>
                    <div className='half-column'>
                        <AnimationOnScroll animateIn='animate__pulse'>
                        <div className='vis-mis-individual'>
                            <h4>Our Vision</h4>
                            <p>To be one of the highly reputable air conditioning firms in Nigeria. This we intend to achieve by focusing on the above objective. Once our customers are happy then the job is well done.</p>
                        </div>
                        </AnimationOnScroll>
                        
                    </div>
                    <div className='half-column'>
                    <AnimationOnScroll animateIn='animate__pulse'>
                    <div className='vis-mis-individual'>
                        <h4>Our Mission</h4>
                        <p>
                        To provide our clients with the best possible air conditioning services. This we hope to do by carrying out the job based on our clients’ scope and desire using our professional skills and ideas to achieve the best possible results in other to satisfy them.
                        </p>
                    </div>
                    </AnimationOnScroll>
                    </div>
          </div>
                </div>
            </div>
          
        </div>
    )
}

const AboutPage = () => {
    return(
        <div>
            <PageHeader 
                title="About Us"
            />
            <div className='container'>
            <div className='max-width'>
                <div className='two-col-container flex-reverse'>
                    <div className='half-column animate__animated animate__fadeInUp'>
                        <div className='body-container'>
                            <p>
                            AC & Ventilation System Limited is a wholly Nigerian incorporated cooling and ventilation design and installation firm, created out of the flaming passion of the founder for constantly seeking the uncluttering colling needs of people at homes, offices, supermarkets, restaurants, hotels, hospitals, etc.
                            </p>
                        </div>
                        <div className='body-container'>
                            <p>
                            The company started operation on the 1st of June, 2006. We are located at 17, Salami street, Oworonshoki, Lagos.
                            </p>
                        </div>
                    </div>
                    <div className='half-column flex-right'>
                            {/* <img src="https://res.cloudinary.com/dynajosh/image/upload/v1664545201/acvent/repair.jpg" className='repair-image'/> */}
                            <img src="https://res.cloudinary.com/dynajosh/image/upload/v1667173481/acvent/JET_FAN_EMPIRE_COURT.jpg" className='repair-image'/>


                    </div>
                </div>
                    <div className='values-row'>
                    <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className='value-card'>
                            <div>
                            <img src="/images/briefcase.gif" className='value-gif'/>
                            </div>
                            <div>
                                <h4 className='value-title'>Professionalism</h4>
                            </div>
                        </div>
                        </AnimationOnScroll>

                        <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className='value-card'>
                            <div>
                                <img src="/images/quality.gif" className='value-gif'/>
                            </div>
                            <div>
                                <h4 className='value-title'>Quality</h4>
                            </div>
                        </div>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className='value-card'>
                            <div>
                            <img src="/images/satisfaction.gif" className='value-gif'/>
                            </div>
                            <div>
                                <h4 className='value-title'>Client's Satisfaction</h4>
                            </div>
                        </div>
                        </AnimationOnScroll>

                    </div>
                    <div className='values-row mb-50'>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className='value-card'>
                            <div>
                            <img src="/images/dart.gif" className='value-gif'/>
                            </div>
                            <div>
                                <h4 className='value-title'>Prompt Delivery</h4>
                            </div>
                        </div>
                        </AnimationOnScroll>

                        <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className='value-card'>
                            <div>
                            <img src="/images/bulb.gif" className='value-gif'/>
                            </div>
                            <div>
                                <h4 className='value-title'>New Innovation</h4>
                            </div>
                        </div>
                        </AnimationOnScroll>
                </div>
            </div>
            </div>
            <CallToAction 
                heading="Check out what we do and how we do it"
                buttonText="Our Services"
                url="/services"
            />
           <VisionAndMission />
        </div>
    )
}

export default AboutPage;