import './index.css'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare, faPhoneVolume, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import Profile from '../../docs/Company Profile AC copy.pdf'

const Footer = () => {
    return(
        <footer className='container footer-bg'>
            <div className='max-width'>
            <div className="footer-row">
                <div>
                    <h4 className="footer-title">Why Us</h4>
                    <div>
                        <p className='footer-text'>
                        Our vision, core values and unwavering dedication to create functional and unique service, stand as a guide to ensuring we offer absolutely nothing but that perfect service for our clients.
                        </p>
                    </div>
                </div>
                <div>
                <h4 className="footer-title">Quick Links</h4>
                    <div>
                        <Link to="/services">
                            <h6 className='footer-link'><span className='mr-10'><FontAwesomeIcon icon ={faArrowUpRightFromSquare} /></span> Services</h6>
                        </Link>
                        <Link to="/contact">
                        <h6 className='footer-link'><span className='mr-10'><FontAwesomeIcon icon ={faArrowUpRightFromSquare} /></span> Contact</h6>
                        </Link>
                        <Link to="/about">
                        <h6 className='footer-link'><span className='mr-10'><FontAwesomeIcon icon ={faArrowUpRightFromSquare} /></span> Values</h6>
                        </Link>
                        <a href = {Profile}className='footer-link'><span className='mr-10'><FontAwesomeIcon icon ={faArrowUpRightFromSquare} /></span> Company Profile</a>
                    </div>
                </div>
                <div>
                <h4 className="footer-title">Reach Us</h4>
                    <div>
                    <Link to="/values">
                        <h6 className='footer-link'><span className='mr-10'><FontAwesomeIcon icon ={faPhoneVolume} /></span> +234 802 354 0879</h6>
                        <h6 className='footer-link'><span className='mr-10'><FontAwesomeIcon icon ={faPhoneVolume} /></span> +234 706 223 3183</h6>
                        <h6 className='footer-link'><span className='mr-10'><FontAwesomeIcon icon ={faEnvelopeOpen} /></span>info@acventilationsystems.com</h6>
                        <h6 className='footer-link'><span className='mr-10'><FontAwesomeIcon icon ={faEnvelopeOpen} /></span>acventilationsystemsltd@gmail.com</h6>

                        </Link>
                    </div>
                </div>
            </div>
            </div>
            
        </footer>
    )
}

export default  Footer;