
import Home from './screens/home';
import {BrowserRouter, Routes, Route,} from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import AboutPage from './screens/about';
import Contactpage from './screens/contact';
import ServicesPage from './screens/services';
import ProjectsPage from './screens/projects';
import "animate.css/animate.min.css";
import ScrollToTop from './components/scroll-totop';



function App() {
  return (
    <BrowserRouter>
    <ScrollToTop>
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<Contactpage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/projects" element={<ProjectsPage />} />
    </Routes>
    <Footer />
    </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
