import './index.css'

const PageHeader = ({title}) => {
    return (
        <div className="page-header-bg">
            <div className='container'>
                <div className='max-width'>
                    <h4 className='inner-page-title'>{title}</h4>
                </div>
            </div>
        </div>
    )
}

export default PageHeader;