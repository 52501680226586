import { Link, NavLink } from "react-router-dom";
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHamburger, faClose, faArrowRight, faPhone, faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';




const WebMenuItem = ({title, url}) => {
    return (    
            <NavLink to={url} 
            className={
                ({isActive})=> isActive ? 'active-menu' : 'inactive-menu'
            }
            > {title}
            </NavLink>
                
       
    )
}

const MobileMenuItem = ({title, url, onClick}) => {
    return (    
            <NavLink to={url} 
            className={
                ({isActive})=> isActive ? 'active-menu' : 'inactive-menu'
            }
            onClick={onClick}
            > {title}
            </NavLink>
                
       
    )
}

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)
    return(
        <>
        <div className="container">
        <div className="max-width">
            <div className="nav-wrapper desktop-only">
                <div className="logo-wrapper">
                    <img src="https://res.cloudinary.com/dynajosh/image/upload/v1664536288/logo.png"  className="logo"/>
                </div>
                <div className="menu-bar">
                <WebMenuItem 
                    title="Home"
                    url="/"
                />
                <WebMenuItem 
                    title="About"
                    url="/about"
                />
                <WebMenuItem 
                    title="Services"
                    url="/services"
                />
                <WebMenuItem 
                    title="Projects"
                    url="/projects"
                />
                <WebMenuItem 
                    title="Reach Us"
                    url="/contact"
                />
                </div>
            </div>
        </div>
        </div>


        <div className="mobile-only">
            <div className="mobile-nav-wrapper">
            <img src="https://res.cloudinary.com/dynajosh/image/upload/v1664536288/logo.png"  className="logo"/>
            {
                isOpen ? (
                    <h4 className="menu-icon" 
                        onClick={()=>setIsOpen(false)}
                    ><FontAwesomeIcon icon={faClose}/></h4>
                ) : (
                    <h4 className="menu-icon"
                    onClick={()=>setIsOpen(true)}
                ><FontAwesomeIcon icon={faBars}/></h4>
                )
            }
            </div>
            {
                isOpen ? (
                    <div className="mobile-menu-container animate__animated animate__fadeInBottomRight">
                        
                    <MobileMenuItem
                        title="Home"
                        url="/"
                        onClick={()=>setIsOpen(false)}
                    />
                    <MobileMenuItem
                    title="About"
                    url="/about"
                    onClick={()=>setIsOpen(false)}
                    />
                    <MobileMenuItem
                    title="Services"
                    url="/services"
                    onClick={()=>setIsOpen(false)}
                    />
                    <MobileMenuItem
                    title="Projects"
                    url="/projects"
                    onClick={()=>setIsOpen(false)}
                    />
                     <MobileMenuItem
                    title="Reach Us"
                    url="/contact"
                    onClick={()=>setIsOpen(false)}
                    />
                    <a href="tel:+2348023540879">
                    <div className='mobile-menu-button' onClick={()=>setIsOpen(false)}>
                        <div className='service-title-container'>
                            <h4>Let's Talk</h4>
                        </div>
                        <div className='service-icon-container'>
                            <h4><FontAwesomeIcon icon={faPhone}/></h4>
                        </div>
                    </div>
                    </a>
                    </div>
             ) : null
            }
            
        </div>
        </>

    )
    
}

export default Navbar;