import './index.css';
import CallToAction from '../../components/cta';
import PageHeader from '../../components/header';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';

 
const ServicesPage = () => {
    return(
        <div>
             <PageHeader 
                title="Our Services"
            />
            <div className='container'>
                <div className='max-width' id="airconditioning">
                    {/* ROW 1 */}
                    <div className='two-col-container my-20 animate__animated animate__fadeInUp'>
                        <div className='half-column'>
                            <h4 className='inner-service-title'>Air Condition Unit</h4>
                            <p className='inner-service-body'>We have a great team of technicians, equipment and inventory to help you either choose a new air conditioner or replace your old ones. We take into account the necessary area and expected occupant a space is to have before recommending air condition specifications.</p>
                            <Link to ="/contact">
                                <button className="cta-button">
                                    Get a Quote
                                </button>
                            </Link>
                        
                        </div>
                        <div className='half-column'>
                            {/* <img src="https://res.cloudinary.com/dynajosh/image/upload/v1664280886/acvent/tropical.jpg" className='inner-service-image'/> */}
                            <div>
                                <h4 className='subtitle'>Chilled Water Unit</h4>
                                <p className='inner-service-body'>Chilled water systems provide cooling to a building by using chilled water to absorb heat from the building’s spaces</p>
                            </div>
                            <div>
                                <h4 className='subtitle'>Split Unit</h4>
                                <p className='inner-service-body'>A split air conditioner consists of two main parts – a compressor located outside and an inside air outlet unit. </p>
                            </div>
                            

                        </div>
                    </div>
                </div>
                                    {/* ROW 2 */}

                <div className='grey-bg container' id="ducting">
                    <div className='max-width'>
                    <div className='two-col-container my-20'>
                        {/* <div className='half-column'>
                            <img src="https://res.cloudinary.com/dynajosh/image/upload/v1664280880/acvent/kaiser2.jpg" className='inner-service-image'/>
                        </div> */}
                        <div className=''>
                            <h4 className='inner-service-title'>Ducting</h4>
                            <p className='inner-service-body'>Installing the proper ducting system in your building can help keep your building free of unwanted pests and your air quality at maximum efficiency, especially in the winter months when warm air can escape through cracks and holes around windows and doors. Finding an experienced team that specializes in residential and commercial ducting services can help ensure that you’re getting a job done right, and at the best price possible.</p>
                            <Link to ="/contact">
                                <button className="cta-button">
                                    Get a Quote
                                </button>
                            </Link>
                        </div>
                    </div>

                    </div>
                    
                    
                </div>

                <div className='max-width' id="fire">
                     <AnimationOnScroll animateIn="animate__fadeInUp">
                     <div className='two-col-container my-20 flex-reverse'>
                        <div className=''>
                            <h4 className='inner-service-title'>Fire Protection</h4>
                            <p className='inner-service-body'>All buildings should have fire prevention systems and precautions in place to keep the public safe during an emergency situation. It’s critical that all buildings, especially high-rise buildings, are equipped with a reliable and easy-to-use fire alarm system that contains smoke detectors and sprinkler systems. It’s also important that these systems are serviced frequently by qualified professionals to ensure they continue to work effectively and efficiently for as long as possible. Fire sprinkler systems are one of the most important pieces of any fire prevention system, as they immediately stop the spread of flames through water.
                            </p>
                            <Link to ="/contact">
                                <button className="cta-button">
                                    Get a Quote
                                </button>
                            </Link>
                        </div>

                        {/* <div className='half-column'>
                            <img src="https://res.cloudinary.com/dynajosh/image/upload/v1664280884/acvent/somerset.jpg" className='inner-service-image'/>
                        </div> */}
                    </div>
                    </AnimationOnScroll>
                </div>
            </div>
            <CallToAction 
                heading="Check out what we do and how we do it"
                buttonText="Our Services"
                url="/services"
            />
        </div>
    )
}

export default ServicesPage;

