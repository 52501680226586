import './index.css';



const Hero = () => {
    return(
        <div className='landing-section'>
            <div className='container'>
                <div className='max-width'>
                    <div className='hero-section'>
                    <div className='hero-row'>
                        <h1 className='hero-text animate__animated animate__slideInLeft' data-aos="fade-zoom-in">AC & Ventilation Systems</h1>
                        <p className='hero-body animate__animated animate__slideInUp'>With over 16 years of experience in the HVAC industry we have earned our position as a leading compay in the industry and are your go-to company for  refrigeration and air conditioning services.</p>
                    </div>
                    {/* <div className='half-column pulse-bg'>
                        <img src="/images/hero.svg" className='hero-image animate__heartBeat'/>
                    </div> */}
                    </div>
                </div>
                
            </div>
            
        </div>
       
    )
}

export default Hero;