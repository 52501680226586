import './index.css'
import Lottie from "lottie-react";
import SentAnimation from '../../lotties/sent-lottie.json'
import SpinnerAnimation from '../../lotties/spinner.json'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLocationPin, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import GoogleMapReact from 'google-map-react';
import PageHeader from '../../components/header';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';



const ContactPage = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitted, SetSubmitted] = useState(false)
    
    const form = useRef();

    const submitForm = (e) => {
        setIsSubmitting(true)
        e.preventDefault();
        emailjs.sendForm('service_neo08ri', 'template_q992avs', form.current, 'oKz-f8t_Zi9FU8-rx')
        .then((result) => {
          console.log(result.text);
          setIsSubmitting(false)
          SetSubmitted(true)
        }, (error) => {
          console.log(error.text);
          setIsSubmitting(false)
        });
    }
    return(
        <div>
            <PageHeader 
                title="Contact"
            />
            <div className='grey-bg py-50'>
                <div className='container'>
                    <div className='max-width'>
                        <div className='contact-bg-white'>
                        <div className='contact-details-card'>
                            <h4 className='contact-details-title '>Contact Information</h4>
                            <p className='contact-details-tagline'>Fill up the form and our team will get back to you within 24 hours.</p>
                            <div className='contact-icons-wrapper'>
                                <div className='contact-item-row'>
                                    <span className='contact-icon'><FontAwesomeIcon icon={faPhone} /></span><p>+234 802 354 0879</p>
                                </div>
                                <div className='contact-item-row'>
                                    <span className='contact-icon'><FontAwesomeIcon icon={faEnvelope} /></span><p>info@acventilationsystems.com</p>
                                </div>
                                <div className='contact-item-row'>
                                    <span className='contact-icon'><FontAwesomeIcon icon={faLocationPin} /></span><p>17 Salami Street, Oworonshoki, Lagos.</p>
                                </div>
                            </div>
                                
                            
                        </div>
                        <div className='contact-form-area'>
                            {
                                submitted ? (
                                    <div style={{
                                        display:'flex',
                                        flexDirection:'column',
                                        alignItems:'center',
                                    }}>
                                        <Lottie animationData={SentAnimation} loop={true} className="sent-animation"/>
                                    <h4 className='value-title' style={{
                                        textAlign:'center',
                                    }}>We have received your message.<br/>Our team would reach out to you in a jiffy!</h4>
                                    </div>
                                ) : (
                                    <form ref={form} onSubmit={submitForm}>
                                        <div className="form-row">
                                            <label className="contact-form-label">Name</label>
                                            <input type="text" name="name" for="name" className='form-input'/>
                                        </div>
                                        <div className="form-row">
                                        <label className="contact-form-label">Phone</label>
                                            <input type="number" name="phone" for="phone"className='form-input'/>
                                        </div>
                                        <div className="form-row">
                                        <label className="contact-form-label">Email</label>
                                            <input type="email" name="email" for="phone" className='form-input'/>
                                        </div>
                                        <div className="form-row">
                                        <label className="contact-form-label">Message</label>
                                            <textarea type="text" name="message" for="phone" className='message-input'/>
                                        </div>
                                        <div className='submit-row'>
                                            <button className='submit-button button-items-row' type='submit'>
                                                {
                                                    isSubmitting ? (
                                                        <>
                                                    <span className='mr-10'>Sending </span> 
                                                    <Lottie animationData={SpinnerAnimation} loop={true} className="button-icon"/>
                                                    </>
                                                    ) : 
                                                    (<>
                                                <span className='mr-10'>Send Message </span>
                                                            <FontAwesomeIcon icon ={faPaperPlane} />
                                                    </>
                                                        
                                                        
        
                                                    )
                                                }
                                                
                                                
                                            </button>
                                        </div>
                                    </form>  
                                )
                            }
                        
                        </div>
                        </div>
                        
                    </div>
                </div>
            </div> 
           
        </div>
    )
}



export default ContactPage;