import { Link } from 'react-router-dom';
import './index.css';

const CallToAction = ({heading, buttonText, url}) => {
    return(
        <div className="cta-row">
            <h4 className=''>{heading}</h4>
            <Link to={url}>
                <button className="cta-button">
                    {buttonText}
                </button>
            </Link>
            
        </div>
    )
}

export default CallToAction;