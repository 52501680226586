import WhoWeAre from "../../components/about-us";
import Hero from "../../components/hero";
import Services from "../../components/services";

const Home = () => {
    return(
        <div>
            <Hero />
            <WhoWeAre />
            <Services />
        </div>
    )
}
export default Home;