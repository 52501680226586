import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightLong } from '@fortawesome/free-solid-svg-icons'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';



const WhoWeAre = () => {
    return(
        <div className=' about-bg container'>
            <div className="max-width">
            
                <div className='mb-20'>
                <AnimationOnScroll animateIn="animate__bounceIn mp-15">
                    <h1 className="section-title">Who Are We?</h1>
                </AnimationOnScroll>
                </div>
                <div className="two-col-container pt-0">
                    <div className='half-column'>
                        <img src ="https://res.cloudinary.com/dynajosh/image/upload/v1666958475/acvent/JEWEL_EVENT_CENTER_COMPLETED_AC_WORK.jpg" className="repair-image"/>
                        {/* <img src ="/images/demo.png" className="repair-image"/> */}
                    </div>
                    <div className='half-column'>
                        <AnimationOnScroll animateIn="animate__fadeInUp">
                        <div className='body-container'>
                        <p>We are a wholly Nigerian incorporated cooling and ventilation design and installation firm, created out of the flaming passion of the founder for constantly seeking the uncluttering cooling needs of people at homes, offices, supermarkets, restaurants, hotels, hospitals, etc. 
                        </p>
                        <p>
                        The company started operation on the 1st of June, 2006. We are located at 17, Salami street, Oworonshoki, Lagos.</p>
                        </div> 
                        </AnimationOnScroll>
                        <Link to ="/about">
                        <button className='about-button'>
                            More About Us 
                            <FontAwesomeIcon icon ={faRightLong} />
                        </button> 
                        </Link>
                    </div>
                   
                </div>
            </div>

        </div>
    )
}

export default WhoWeAre;