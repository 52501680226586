import './index.css'
import data from './data.json'
import CallToAction from "../../components/cta";
import PageHeader from "../../components/header";
import toast, { Toaster } from 'react-hot-toast';



const ProjectsPage = () => {
    const notify = () =>  toast.success("No images yet");


    const Project = ({title, desc,image })=>{
        return(
            <div className=" animate__animated animate__fadeInDown">
                {/* <img src={image} className="project-image"/> */}
                <div className="project-desc-container">
                    <h4 className="project-title">{title}</h4>
                    <h4 className="project-desc">{desc}</h4>
                    {/* <button className="project-button" onClick={()=>{notify()}}>View Project</button> */}
                </div>
            </div>
        )
    }
    
    return(
        <>
        <Toaster
            position="bottom-right"

        />
        <PageHeader 
                title="Projects"
            />
        <div className="wp-50">
            <div className="container">
                <div className="max-width">
                    {/* <div className="values-row"> */}
                        {
                            data.map((project)=>{
                                return (
                            <Project 
                            title={project.title}
                            desc={project.desc}
                            image = {project.image}
                        />)
                            })
                        }
                        
                    {/* </div> */}
                </div>
            </div>
        </div>
        <CallToAction 
        heading="Impressed by our works?"
        buttonText="Books yours"
        url="/contact"
    />
    </>
    )
}

export default ProjectsPage;