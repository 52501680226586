import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import CallToAction from '../cta'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


const Services = () => {
    return(
        <div className=''>
            <div className='container pt-50'>
            <div className='max-width'>
                <div className='flex-center mp-15'>
                <AnimationOnScroll animateIn="animate__bounceIn">
                <h1 className="section-title">Our Services</h1>
                </AnimationOnScroll>
                </div>
            </div>
            </div>
            <div className='container pb-50'>
            <div className="max-width">
                <div className='values-row'>
                <HashLink to="/services/#aircondition">
                <div className='service-card'>
                    <h4 className='inner-service-title text-center text-blue'>Air conditioning</h4>
                    <p className='inner-service-body text-center w-100 text-black'>We have a great team of technicians, equipment and inventory to help you either choose a new air conditioner or replace your old ones...</p>
                    <h4 className='text-center text-blue learn-more'>Learn More</h4>
                </div>
                </HashLink>

                
                <HashLink to="/services/#ducting">
                <div className='service-card '>
                    <h4 className='inner-service-title text-center text-blue'>Ducting</h4>
                    <p className='inner-service-body text-center w-100 text-black'> Ducting can be as simple as an HVAC vent that connects your central heating system to the various rooms of your home, or it can be complex and contain...</p>
                    <h4 className='text-center text-blue learn-more'>Learn More</h4>
                </div>
                </HashLink>

                <HashLink to="/services#fire">
                <div className='service-card'>
                <h4 className='inner-service-title text-center text-blue'>Fire Protection System</h4>
                <p className='inner-service-body text-center w-100 text-black'> All buildings should have fire prevention systems and precautions in place to keep the public safe during an emergency situation. It’s critical that all buildings...</p>
                    <h4 className='text-center text-blue learn-more'>Learn More</h4>
                </div>
                </HashLink>

                </div>
            </div>
            </div>
            <CallToAction 
                heading="Want to know what we've done?"
                buttonText="See Our Projects"
                url="/projects"
            />

        </div>
    )
}

export default Services;